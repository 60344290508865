// @ts-strict-ignore
import { post } from 'bb/api/browser/api';
import {
    type AnalyticsEventsStrategy,
    type AnalyticsEvent,
    type AnalyticsBuyGiftcardCompleteData
} from 'bb/tracker/types';
import { isBrowser, match } from 'bb/utils';

const pushAnalyticsEventMessage = async (event: AnalyticsEvent) => {
    await post<Response>(
        '/api/analytics/events',
        event as Record<string, unknown>
    );
};

const pushBuyGiftcardCompleteEvent = async (
    data: AnalyticsBuyGiftcardCompleteData
) => {
    await post<Response>('/api/analytics/events', {
        source: data.source,
        category: 'giftcards',
        action: 'buy giftcard complete',
        label: data.productName
    });
};
export const isAnalyticsEvent = (data): boolean =>
    data.source === 'Registration' || data.source === 'AccountPage';
export const isBuyGiftcardComplete = (data): boolean =>
    data.event === 'BuyGiftcardComplete';

let analyticsEventStrategy: AnalyticsEventsStrategy = null;

export const createAnalyticsEventStrategy = (): AnalyticsEventsStrategy => {
    if (!analyticsEventStrategy) {
        analyticsEventStrategy = {
            track: (data): void => {
                if (isBrowser()) {
                    match(data)
                        .on(isAnalyticsEvent, pushAnalyticsEventMessage)
                        .on(isBuyGiftcardComplete, pushBuyGiftcardCompleteEvent)
                        .otherwise(() => false);
                }
            }
        };
    }
    return analyticsEventStrategy;
};
