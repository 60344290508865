/* eslint-disable no-plusplus */
import React from 'react';
import { type Range, type Tuple } from 'bb/common/types';
import { useTranslation } from 'bb/i18n';
import { getDefaultMessages, getStrengthBorderColor } from '../helpers';
import { PasswordInput, type PasswordInputProps } from './PasswordInput';

/**
 * Copied from old PasswordStrength component.
 */
export function calculateStrength(password = '') {
    let strength = 0;
    const { length } = password;

    if (length >= 6) {
        strength++;

        if (
            /[A-Z]/.test(password) &&
            /[a-z]/.test(password) &&
            (/\W/.test(password) || /\d/.test(password))
        ) {
            strength++;
        } else if (/\W/.test(password) && /\d/.test(password)) {
            strength++;
        }
    }

    if (length >= 8) {
        strength++;
    }

    if (length >= 12) {
        strength++;
    }

    return strength as Range<0, 4>;
}
export type CreatePasswordInputProps = {
    messages?: Tuple<string, 5>;
    /**
     * The base Input component supports two helperText(s)
     * but since we are showing the password strength at we
     * only allow passing a single helperText.
     */
} & PasswordInputProps;

export const CreatePasswordInput = ({
    ref,
    ...props
}: CreatePasswordInputProps) => {
    const { t } = useTranslation(['inputFields']);

    const {
        messages = getDefaultMessages(t),
        value,
        label = t('inputFields:labelCreatePassword'),
        placeholder = t('inputFields:placeholderCreatePassword'),
        helperText: passedHelperText = t('inputFields:infoCreatePassword'),
        ...restProps
    } = props;

    const helperText = Array.isArray(passedHelperText)
        ? passedHelperText[0]
        : passedHelperText;

    const strength =
        typeof value === 'string' && value.length > 0
            ? calculateStrength(value)
            : null;

    return (
        <PasswordInput
            borderColor={getStrengthBorderColor(strength)}
            ref={ref}
            value={value}
            helperText={[
                strength != null ? messages[strength] : ' ',
                helperText
            ]}
            label={label}
            placeholder={placeholder}
            {...restProps}
        />
    );
};
