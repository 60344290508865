/* eslint-disable @typescript-eslint/no-empty-object-type */
import { type WithRequired } from 'bb/common/types';
import { type FilteredPathKey, type MutationMethods } from '../../types';
import { useMutation } from './useMutation.hook';
import {
    type GetDetails,
    type UseMutationSchemeData,
    type UseMutationOptions
} from './useMutation.types';

/**
 * @param method 'post' | 'put' | 'delete'
 * @param pathname pathname to the endpoint
 * @param defaultApiOptions default options passed to useMutation
 */
export function createUseMutationHook<
    PayloadOverride,
    ResponseOverride,
    Method extends MutationMethods = MutationMethods,
    Path extends FilteredPathKey<Method> = FilteredPathKey<Method>,
    Scheme extends UseMutationSchemeData<Method, Path> = UseMutationSchemeData<
        Method,
        Path
    >,
    PayloadParams = PayloadOverride extends object
        ? PayloadOverride
        : GetDetails<Method, Path, 'body'> extends {}
          ? GetDetails<Method, Path, 'body'>
          : 'no-params',
    Response = ResponseOverride extends object
        ? ResponseOverride
        : GetDetails<Method, Path, 'response'>,
    PathQueryParams = Scheme extends { parameters: infer Params }
        ? Params extends { path: infer PathParams }
            ? PathParams
            : 'no-params'
        : 'no-params',
    Params = PathQueryParams extends 'no-params' ? {} : PathQueryParams,
    Payload = PayloadParams extends 'no-params' ? {} : PayloadParams,
    Options extends UseMutationOptions<
        Payload & Params,
        Response
    > = UseMutationOptions<Payload & Params, Response>
>(method: Method, pathname: Path, defaultOptions?: Options) {
    return (
        ...args: PathQueryParams extends object
            ? [options: WithRequired<Options, 'query'>]
            : [options?: Options]
    ) =>
        useMutation<Payload & Params, Response, 'generic'>(method, pathname, {
            ...defaultOptions,
            ...args[0]
        });
}
