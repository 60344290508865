import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Stack } from '../../Flex';
import css from './formMessage.module.scss';

export type FormMessageProps = {
    status: {
        message?: string | Array<string | React.ReactElement> | null;
    };
    testId?: string;
    disablePadding?: boolean;
    className?: string;
    ref?: React.RefObject<HTMLDivElement>;
} & PropsWithChildren;

export const FormMessage = ({
    ref,
    status,
    testId,
    children,
    disablePadding = false,
    className
}: FormMessageProps) => {
    const { message } = status;

    if (!message) {
        return null;
    }

    return (
        <Stack aria-live="assertive" role="alert" tabIndex={-1} ref={ref}>
            <span
                className={classNames(
                    css.message,
                    disablePadding && css.disablePadding,
                    className
                )}
                data-testid={testId}
                data-key="form-error-message-button"
            >
                {message}
                {children}
            </span>
        </Stack>
    );
};
