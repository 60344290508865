import React, { type CSSProperties } from 'react';
import classnames from 'classnames';
import commonCss from 'bb/style/common.module.scss';
import { type Color, type SpacingProperty } from 'bb/style/types';
import { makeCSSVariableFromColor, makeSpacingValue } from 'bb/style/utils';
import { isDef } from 'bb/utils/assert';
import { getMargins, type MarginProps } from '../Margin';
import { type PaddingProps } from '../Padding';
import { getPaddings } from '../Padding/getPaddings';
import { type PolymorphicComponent } from '../types';
import css from './box.module.scss';

export const CSS_VARIABLE_PREFIX = '--box-padding';

export const isPaddingSpacingProperty = (
    padding: BoxProps['padding']
): padding is SpacingProperty => typeof padding !== 'string' && isDef(padding);

export const makeCSSVariablesFromPadding = (padding: SpacingProperty) => {
    if (!Array.isArray(padding) && typeof padding === 'object') {
        return Object.entries(padding).reduce(
            (object, [key, value]) => ({
                ...object,
                [`${CSS_VARIABLE_PREFIX}-${key}`]: makeSpacingValue(value)
            }),
            {}
        ) as CSSProperties;
    }

    return {
        [`${CSS_VARIABLE_PREFIX}-xxs`]: makeSpacingValue(padding)
    } as CSSProperties;
};

export const makeBorderVariables = (border: BoxProps['border']) => {
    const [borderWidth, borderStyle, borderColor] = (border ?? '').split(' ');

    return {
        ...makeCSSVariableFromColor('--box-border-color', borderColor as Color),
        '--box-border-width': borderWidth,
        '--box-border-style': borderStyle
    };
};

export const makePaddingClassNames = (padding: SpacingProperty) => {
    if (!Array.isArray(padding) && typeof padding === 'object') {
        return Object.keys(padding).map((key) => css[`padding-${key}`]);
    }

    return [css['padding-xxs']];
};

export type LegacyPaddingProperties = {
    /**
     * @deprecated use @type {SpacingProperty} instead.
     */
    padding?: PaddingProps['padding'];
    /**
     * @deprecated use @type {SpacingProperty} instead.
     */
    paddingTop?: PaddingProps['paddingTop'];
    /**
     * @deprecated use @type {SpacingProperty} instead.
     */
    paddingRight?: PaddingProps['paddingRight'];
    /**
     * @deprecated use @type {SpacingProperty} instead.
     */
    paddingBottom?: PaddingProps['paddingBottom'];
    /**
     * @deprecated use @type {SpacingProperty} instead.
     */
    paddingLeft?: PaddingProps['paddingLeft'];
};

export type BoxBorderStyle = 'solid' | 'dashed' | 'dotted';

export type BoxBaseProps = {
    /**
     * Used to opt in or out of width: 100%.
     * Defaults to true in order to not break
     * old implementations where css.base always
     * applied that.
     */
    fluid?: boolean;
    /**
     * Used to opt in or out of flex-grow: 1.
     *
     * @defaultValue `false`
     */
    grow?: boolean;
    /**
     * The background color of the box.
     */
    bgColor?: Color;
    /**
     * It is recommended to use @type {SpacingProperty} to
     * set padding since it uses the spacing units and handles
     * responsitivity.
     *
     * @type {LegacyPaddingProperties['padding']} is still
     * supported for backwards compatibility, but is outdated.
     */
    padding?: SpacingProperty | LegacyPaddingProperties['padding'];
    /**
     * This property is designed to replicate the native way
     * of setting a border in CSS. However, since we have our
     * own theme colors, we need to only allow those.
     */
    border?: `${number}px ${BoxBorderStyle} ${Color}`;
};

export type BoxProps<TElementType extends React.ElementType = 'div'> =
    PolymorphicComponent<
        TElementType,
        BoxBaseProps & MarginProps & Omit<LegacyPaddingProperties, 'padding'>
    >;

export const Box = (({
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    padding,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
    className = '',
    ref,
    role,
    style,
    as: Component = 'div',
    children,
    fluid = true,
    grow = false,
    bgColor,
    border,
    ...restProps
}: BoxProps<'div'>) => (
    <Component
        ref={ref}
        className={classnames(
            css.root,
            fluid && commonCss.fluid,
            grow && commonCss.grow,
            border && css.borderStyles,
            className,
            getMargins({
                margin,
                marginLeft,
                marginRight,
                marginBottom,
                marginTop
            }),
            !isPaddingSpacingProperty(padding) &&
                getPaddings({
                    padding,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    paddingTop
                }),
            isPaddingSpacingProperty(padding) &&
                makePaddingClassNames(padding).join(' ')
        )}
        role={role}
        style={{
            ...style,
            ...makeCSSVariableFromColor('--box-background-color', bgColor),
            ...(isPaddingSpacingProperty(padding)
                ? makeCSSVariablesFromPadding(padding)
                : {}),
            ...(border ? makeBorderVariables(border) : {})
        }}
        {...restProps}
    >
        {children}
    </Component>
)) as <TElementType extends React.ElementType = 'div'>(
    props: BoxProps<TElementType>
) => JSX.Element;
