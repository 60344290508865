import React from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import { useApp } from 'bb/app/AppContext';
import { useRouter, type Route, type RouterQuery } from 'bb/app/router';
import { type Routes, type Route as TypedRoute } from 'bb/app/router/config';
import { useGetRoutesFromRemappedRoutes } from 'bb/app/router/useGetRoutesFromRemappedRoutes';
import { usePersistQueryParams } from 'bb/app/router/usePersistQueryParams';
import { getUrl } from 'bb/app/router/utils';
import { Typography, type TypographyProps } from 'bb/ui/Typography';
import { useLinkConfig } from '../LinkConfigContext';

export type LinkProps = {
    route: Route;
    query?: RouterQuery;
    /**
     * If `true` the NextLink won't be used and the link will be a normal anchor tag.
     *
     * @defaultValue `false`
     */
    reload?: boolean;
} & TypographyProps<'a'> &
    Pick<NextLinkProps, 'prefetch' | 'shallow' | 'scroll'>;

export const Link = ({ ref, ...props }: LinkProps) => {
    const linkConfigCtx = useLinkConfig();
    const { routes } = useRouter();
    const { make } = usePersistQueryParams();
    const getRouteFromRemappedRoutes = useGetRoutesFromRemappedRoutes();
    const linkProps = { ...props, ...linkConfigCtx };

    const {
        route: originalRoute,
        query,
        children,
        prefetch = false,
        shallow,
        scroll,
        reload = false,
        ...restProps
    } = linkProps;

    const { market } = useApp();

    const route =
        routes[
            getRouteFromRemappedRoutes(originalRoute as Routes[TypedRoute])
        ] ?? originalRoute;

    if (reload) {
        return (
            <Typography
                href={`${getUrl(route.href, { market, ...query, ...make() })}`}
                ref={ref}
                as="a"
                {...restProps}
            >
                {children}
            </Typography>
        );
    }

    return (
        <NextLink
            prefetch={prefetch}
            passHref
            href={{
                pathname: route.href,
                query: { ...query, ...make(), market }
            }}
            locale=""
            legacyBehavior
            shallow={shallow}
            scroll={scroll}
        >
            <Typography ref={ref} as="a" {...restProps}>
                {children}
            </Typography>
        </NextLink>
    );
};
