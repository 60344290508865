import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef
} from 'react';
import queryString from 'query-string';
import { type MutatorOptions, SWRConfig, useSWRConfig } from 'swr';
import { areObjectsEqual } from 'bb/utils/areObjectsEqual';
import { FIXED_OPTIONS } from './swr';

export type HalLiveQueriesRevalidateKey =
    | 'paymentinformation'
    | 'paymentmethod'
    | 'mynextpayment'
    | 'mycurrentperiod'
    | 'subscription'
    | 'personaloffers'
    | 'profilecampaign';

export type HalLiveQueriesContextType = {
    /**
     * A map containing a revalidate key that maps to a href.
     */
    state: Map<HalLiveQueriesRevalidateKey, string>;
    /**
     * Refreshes the data for a given revalidate key.
     *
     * If no href is found for the given key, nothing will happen.
     *
     * For this to work a `revlidateKey` must be set in the `useHALGet` options.
     */
    refresh: (
        key: HalLiveQueriesRevalidateKey,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options?: Omit<MutatorOptions<any, any>, 'revalidate'>
    ) => Promise<void>;
};

export type HalLiveQueriesProviderProps = React.PropsWithChildren;

export const HalLiveQueriesContext = createContext<HalLiveQueriesContextType>({
    state: new Map(),
    refresh: async () => {}
});

export const HalLiveQueriesProviderInner = (
    props: HalLiveQueriesProviderProps
) => {
    const { children } = props;
    const { mutate, cache } = useSWRConfig();
    const stateRef = useRef(new Map<HalLiveQueriesRevalidateKey, string>());

    const refresh: HalLiveQueriesContextType['refresh'] = useCallback(
        async (key, options = {}) => {
            const savedHref = stateRef.current.get(key);

            if (savedHref) {
                /**
                 * SWR throws query parameters around which makes
                 * our system for finding the correct cache key hard.
                 * For that reason we need to parse both the saved href
                 * and the cache key and compare them. If we get a match
                 * we can revalidate the cache key.
                 */
                const { query: savedQuery, url: savedUrl } =
                    queryString.parseUrl(savedHref);

                const cacheKeys = cache.keys();
                const cachedHref = Array.from(cacheKeys).find((cacheKey) => {
                    const { query: cachedQuery, url: cachedUrl } =
                        queryString.parseUrl(cacheKey);

                    return (
                        savedUrl === cachedUrl &&
                        areObjectsEqual(savedQuery, cachedQuery)
                    );
                });

                if (cachedHref) {
                    await mutate(cachedHref, undefined, {
                        revalidate: true,
                        ...options
                    });
                }
            }
        },
        [mutate, cache]
    );

    const ctx: HalLiveQueriesContextType = useMemo(
        () => ({
            state: stateRef.current,
            refresh
        }),
        [refresh]
    );

    return (
        <HalLiveQueriesContext.Provider value={ctx}>
            {children}
        </HalLiveQueriesContext.Provider>
    );
};

export const HalLiveQueriesProvider = (props: HalLiveQueriesProviderProps) => (
    <SWRConfig value={{ ...FIXED_OPTIONS, revalidateOnMount: true }}>
        <HalLiveQueriesProviderInner {...props} />
    </SWRConfig>
);

export const useHalLiveQueries = () => useContext(HalLiveQueriesContext);
