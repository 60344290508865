import React from 'react';
import { BaseInput } from './BaseInput';
import { type InputProps } from './Input.types';

export const Input = ({
    ref,
    disableMaxLengthCounter = true,
    ...restProps
}: InputProps) => (
    <BaseInput
        disableMaxLengthCounter={disableMaxLengthCounter}
        as="input"
        ref={ref}
        {...restProps}
    />
);
