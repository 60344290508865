import React, { type ElementType } from 'react';
import classnames from 'classnames';
import {
    type PolymorphicComponent,
    type AlignItems,
    type GapSize
} from '../../types';
import alignCss from '../alignItems.module.scss';
import flexWrap from '../flexWrap.module.scss';
import gapCss from '../gap.module.scss';
import justifyContentCss from '../justifyContent.module.scss';
import { type FlexWrapType, type JustifyContentType } from '../types';
import css from './flex.module.scss';

export type FlexDirection = 'row' | 'column';

export type FlexProps<TElementType extends ElementType = 'div'> =
    PolymorphicComponent<
        TElementType,
        {
            wrap?: FlexWrapType;
            gap?: GapSize;
            alignItems?: AlignItems;
            justifyContent?: JustifyContentType;
            direction?: FlexDirection;
        }
    >;

export const Flex = ((props: FlexProps<'div'>) => {
    const {
        className,
        justifyContent,
        wrap = 'wrap',
        gap,
        alignItems,
        direction = 'row',
        children,
        as: Component = 'div',
        ref,
        ...restProps
    } = props;

    return (
        <Component
            ref={ref}
            className={classnames(
                css.base,
                className,
                css[direction],
                flexWrap[wrap],
                gap && gapCss[gap],
                alignItems && alignCss[alignItems],
                justifyContent && justifyContentCss[justifyContent]
            )}
            {...restProps}
        >
            {children}
        </Component>
    );
}) as <TElementType extends ElementType = 'div'>(
    props: FlexProps<TElementType>
) => JSX.Element;
