import { useMutation } from 'bb/api/browser';
import { useRouter } from 'bb/app/router';
import { getUrl } from 'bb/app/router/utils';
import { useMarket } from 'bb/app/useMarket';
import { isBrowser } from 'bb/utils';

export const useLogout = () => {
    const { routes } = useRouter();
    const { market } = useMarket();
    const loginURL = isBrowser()
        ? `${window.location.origin}${getUrl(routes.login.href, { market })}`
        : '';

    const res = useMutation('post', '/api/logout', {
        onSuccess: () => {
            window.location.replace(loginURL);
        }
    });

    return Object.assign(res, { logout: () => res.trigger({}) });
};
