import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useOverflow } from 'bb/common/hooks/useOverflow';
import { Text, type TextProps, useTranslation } from 'bb/i18n';
import {
    CrossIcon,
    ErrorIcon,
    InformationIcon,
    SuccessIcon,
    WarningIcon
} from 'bb/ui/Icons';
import { Card, type CardProps } from '../Cards';
import { type PolymorphicComponent } from '../types';
import { Typography } from '../Typography';
import css from './simpleNotification.module.scss';

export type SimpleNotificationProps<T extends React.ElementType = 'div'> =
    React.PropsWithChildren<
        {
            /**
             * The message that is passed to the Text component.
             */
            message?: React.ReactNode;
            /**
             * onClose handler.
             */
            onClose?: () => void;
            /**
             * All possible variants of notification.
             */
            variant: 'success' | 'warning' | 'error' | 'information';
            /**
             * className passed to Card
             */
            className?: string;
            /**
             * Make notification take up full width.
             */
            fullWidth?: boolean;
            /**
             * Props passed to the Text component.
             */
            textProps?: TextProps;
            /**
             * Sets maxWidth of card in px
             */
            maxWidth?: number;
            /**
             * Limits the rows shown.
             */
            limitRows?: number;
            /**
             * Disables the limit rows and removes line-clamp.
             */
            disableLimitRows?: boolean;
        } & Omit<CardProps<T>, 'backgroundColor'>
    >;

export const VARIANT_PROPS_MAP = {
    success: ['tertiary-green', 'secondary-green', SuccessIcon],
    warning: ['tertiary-yellow', 'secondary-yellow', WarningIcon],
    error: ['tertiary-red', 'secondary-red', ErrorIcon],
    information: ['tertiary-purple', 'primary-purple', InformationIcon]
} as const;

export const SIMPLE_NOTIFICATION_VARIANTS = Object.keys(
    VARIANT_PROPS_MAP
) as SimpleNotificationProps<'div'>['variant'][];

export const SimpleNotification = ((props: SimpleNotificationProps<'div'>) => {
    const {
        message,
        onClose,
        variant,
        className,
        children,
        fullWidth = false,
        as = 'div',
        textProps,
        style: passedStyle,
        maxWidth,
        limitRows = 3,
        disableLimitRows = false,
        ref,
        ...restProps
    } = props;
    const { t } = useTranslation(['common', 'accessibility']);
    /**
     * If textProps.children is passed that will get priority
     * over props.message. Defaults to message if not passed.
     */
    const {
        children: textChildren = message,
        className: textClassName,
        ...restTextProps
    } = textProps ?? {};

    const typographyRef = useRef<HTMLSpanElement | null>(null);
    const [showMore, setShowMore] = useState(false);
    const hasOverflow = useOverflow(typographyRef, [message]);

    const [backgroundColor, , Icon] = VARIANT_PROPS_MAP[variant];

    return (
        <Card
            as={as}
            role="alert"
            className={classNames(
                css.root,
                fullWidth && css.fullWidth,
                className
            )}
            bgColor={backgroundColor}
            ref={ref}
            style={{ ...passedStyle, maxWidth }}
            tabIndex={-1}
            {...restProps}
        >
            <Icon
                aria-label={t(`accessibility:accessibility_${variant}Icon`)}
                size="small"
                className={css.icon}
            />
            <div className={css.box}>
                <Text
                    aria-live="assertive"
                    as="span"
                    ref={typographyRef}
                    style={{ '--limitRows': limitRows } as React.CSSProperties}
                    className={classNames(
                        !disableLimitRows && css.limitRows,
                        showMore && css.showMore,
                        textClassName
                    )}
                    {...restTextProps}
                >
                    {textChildren}
                </Text>
                {hasOverflow && (
                    <button
                        className={css.showMoreButton}
                        onClick={() => setShowMore(!showMore)}
                        type="button"
                    >
                        <Typography bold>
                            {showMore
                                ? t('common:ShowLess')
                                : t('common:showMore')}
                        </Typography>
                    </button>
                )}
                {children}
            </div>

            {onClose && (
                <button
                    aria-label={t('common:close')}
                    className={css.close}
                    type="button"
                    onClick={onClose}
                >
                    <CrossIcon />
                </button>
            )}
        </Card>
    );
}) as <T extends React.ElementType>(
    props: PolymorphicComponent<T, SimpleNotificationProps>
) => JSX.Element;
