import React from 'react';
import { useTranslation } from 'bb/i18n';
import css from './numberOfBooks.module.scss';

export const NUMBER_OF_BOOKS_FALLBACK = 900000;

export const getNumberOfBooks = (numberString: string | undefined) => {
    if (!numberString) return NUMBER_OF_BOOKS_FALLBACK;

    /**
     * Remove all whitespace from the string and parse it as an integer.
     */
    const trimmedNumber = numberString.replace(/\s/g, '');

    /**
     * If the trimmed number is not a finite number, return the original string.
     * The reason this happens is probably because the content editor has entered
     * something like "one million" which is not a valid number.
     */
    if (!Number.isFinite(trimmedNumber)) return numberString;
    const parsedNumber = parseInt(trimmedNumber, 10);

    if (Number.isNaN(parsedNumber)) {
        return NUMBER_OF_BOOKS_FALLBACK;
    }

    return parsedNumber;
};

export const useGetNumberOfBooks = () => {
    const { i18n, t } = useTranslation(['common']);

    const amount = getNumberOfBooks(t('common:numberOfBooks'));

    return () =>
        typeof amount === 'string'
            ? amount
            : new Intl.NumberFormat(i18n.language).format(amount);
};

export const NumberOfBooks = () => (
    <span className={css.base}>{useGetNumberOfBooks()()}</span>
);
