import React, { type ElementType } from 'react';
import classnames from 'classnames';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import commonCss from './button.common.module.scss';
import { ButtonContent } from './ButtonContent';
import css from './textButton.module.scss';
import { type TextButtonProps } from './TextButton.types';

export const TextButton = ((props: TextButtonProps<'button'>) => {
    const {
        as: Component = 'button',
        startAdornment,
        endAdornment,
        children,
        color = 'primary-black',
        className,
        fluid = false,
        block = false,
        isLoading = false,
        disableAnimation = false,
        textProps = {},
        underline = false,
        'data-key': dataKey = 'bb-button',
        ref,
        ...restProps
    } = props;

    return (
        <Component
            {...restProps}
            data-key={dataKey}
            className={classnames(
                css.root,
                fluid && commonCss.fluid,
                block && commonCss.block,
                underline && css.underline,
                isLoading && !disableAnimation && css.isLoading,
                !disableAnimation && css.activeAnimation,
                className
            )}
            style={{
                ...makeCSSVariableFromColor('--button-text-color', color)
            }}
            ref={ref}
        >
            <ButtonContent
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                textProps={{ ...textProps, bold: false }}
            >
                {children}
            </ButtonContent>
        </Component>
    );
}) as <TElementType extends ElementType = 'button'>(
    props: TextButtonProps<TElementType>
) => JSX.Element;
