import React from 'react';
import { FeedbackIcon } from 'bb/ui/Icons/svgs';
import { type TextareaProps } from '../Input.types';
import { Textarea } from '../Textarea';

export type FeedbackTextareaProps = Omit<TextareaProps, 'startAdornment'>;

export const FeedbackTextarea = ({ ref, ...props }: FeedbackTextareaProps) => (
    <Textarea
        startAdornment={<FeedbackIcon size="small" />}
        ref={ref}
        {...props}
    />
);
