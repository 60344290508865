import { useRef } from 'react';
import { useMutation } from 'bb/api/browser';
import { isProductionEnvironment } from 'bb/utils/environment';
import { useAccount } from './useAccount';

export const useMagicLink = () => {
    const magicLinkGenerated = useRef(false);

    const { data, trigger, isMutating } = useMutation(
        'post',
        '/api/generate-magiclink'
    );
    useAccount({
        onData: () => {
            if (isMutating || magicLinkGenerated.current) return;
            /**
             * If we are in development mode we want the
             * origin to be test. Otherwise the endpoint
             * doesn't accept our request.
             */
            const origin = isProductionEnvironment()
                ? window.location.origin
                : 'https://test.bookbeat.com';

            trigger({
                url: `${origin}${window.location.pathname}`
            }).then(() => {
                magicLinkGenerated.current = true;
            });
        }
    });

    return data;
};
