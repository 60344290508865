import React from 'react';
import classNames from 'classnames';
import { BaseInput } from './BaseInput';
import css from './input.module.scss';
import { type TextareaProps } from './Input.types';

export const Textarea = ({
    ref,
    className,
    inputClassName,
    ...restProps
}: TextareaProps) => (
    <BaseInput
        {...restProps}
        inputClassName={classNames(css.textareaInput, inputClassName)}
        className={classNames(css.textarea, className)}
        as="textarea"
        ref={ref}
    />
);
