import React, { type RefAttributes, useRef } from 'react';
import classNames from 'classnames';
import {
    Controller,
    type FieldValues,
    type FieldPath,
    type UseFormProps
} from 'react-hook-form';
import { type Replace } from 'bb/common/types';
import { type useFormValidationTracker } from 'bb/tracker';
import { type CheckboxProps } from '../Checkbox';
import { type InputElementType, type BaseInputProps } from '../Input';
import { type BaseSelectProps } from '../Select';
import { type HookFormControlProps, type HookFormInputProps } from '../types';
import { type UseFormFieldListenerHandler } from './useForm';

export type HookFormSelectInputProps =
    /**
     * react-hook-form prefers to use uncontrolled inputs by default
     * for performance reasons. in this case, since we are not using
     * a native input element we need it to be controlled.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HookFormInputProps & HookFormControlProps;

export function withHookFormCheckbox<TProps extends CheckboxProps>(
    Component: React.ComponentType<TProps>,
    defaultProps?: TProps
) {
    const { className: defaultClassName, ...restDefaultProps } =
        defaultProps ?? {};

    return ({ ref, ...props }: Replace<TProps, HookFormInputProps>) => {
        const {
            error: passedError,
            noErrorFeedback = false,
            className,
            name,
            ...restProps
        } = props;

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Component
                {...restDefaultProps}
                {...restProps}
                name={name}
                className={classNames(defaultClassName, className)}
                error={noErrorFeedback ? undefined : passedError?.message}
                ref={ref}
            />
        );
    };
}

export function withHookFormInput<
    TElementType extends InputElementType,
    TElement extends HTMLElement,
    TProps extends Omit<
        BaseInputProps<TElementType>,
        keyof RefAttributes<TElement>
    >
>(Component: React.ComponentType<TProps>, defaultProps?: TProps) {
    const { className: defaultClassName, ...restDefaultProps } =
        defaultProps ?? {};

    return ({ ref, ...props }: Replace<TProps, HookFormInputProps>) => {
        const {
            error: passedError,
            noErrorFeedback = false,
            className,
            name,
            control,
            ...restProps
        } = props;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const allProps: typeof props = {
            ...restDefaultProps,
            ...restProps,
            name,
            className: classNames(defaultClassName, className),
            error: noErrorFeedback ? undefined : passedError?.message,
            ref
        };

        if (control) {
            return (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Component {...allProps} {...field} />
                    )}
                />
            );
        }

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Component {...allProps} />
        );
    };
}

export function withHookFormSelect<
    TProps extends Omit<BaseSelectProps, 'items'>
>(Component: React.ComponentType<TProps>, defaultProps?: TProps) {
    const { className: defaultClassName, ...restDefaultProps } =
        defaultProps ?? {};

    return ({ ref, ...props }: Replace<TProps, HookFormSelectInputProps>) => {
        const {
            error: passedError,
            className,
            name,
            noErrorFeedback,
            control,
            ...restProps
        } = props;

        return (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, ...restFieldProps } }) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Component
                        {...restDefaultProps}
                        {...restProps}
                        {...restFieldProps}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(value: any) =>
                            onChange({ target: { value } })
                        }
                        name={name}
                        className={classNames(defaultClassName, className)}
                        error={
                            noErrorFeedback ? undefined : passedError?.message
                        }
                        ref={ref}
                    />
                )}
            />
        );
    };
}

/**
 * Returns the default value for a given key from the defaultValues object.
 *
 * If an async function is passed as the default value, undefined will be returned.
 */
export const getSyncDefaultValue = <
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues>
>(
    defaultValues: UseFormProps<TFieldValues>['defaultValues'],
    key: TFieldName
) =>
    typeof defaultValues === 'object' && defaultValues !== null
        ? defaultValues[key]
        : undefined;

export const useOnHookFormValidationError = () => {
    const lastTrackingKey = useRef('');

    return <
        TFieldValues extends FieldValues,
        TFieldName extends FieldPath<TFieldValues>
    >(
        callback: ReturnType<
            typeof useFormValidationTracker
        >['validationError'],
        category = 'unknown'
    ) =>
        ((event, getFieldState) => {
            const fieldState = getFieldState();
            const trackingKey = `${category}|${event.target.name}`;

            if (
                fieldState.isValid === false &&
                fieldState.isTouched &&
                trackingKey !== lastTrackingKey.current
            ) {
                lastTrackingKey.current = trackingKey;
                callback(category, event.target.name);
            }
        }) as UseFormFieldListenerHandler<TFieldValues, TFieldName, 'onBlur'>;
};
