import { delocalizationEnabled } from 'bb/config/marketConfigUtils';
import { getEnvironment } from 'bb/utils/environment';
import { type Market } from './types';

export type MarketConfig = {
    key: string;
    name: string;
    code:
        | 43
        | 32
        | 359
        | 385
        | 357
        | 420
        | 45
        | 372
        | 358
        | 33
        | 49
        | 30
        | 36
        | 353
        | 39
        | 371
        | 370
        | 352
        | 356
        | 31
        | 47
        | 48
        | 351
        | 40
        | 421
        | 386
        | 34
        | 46
        | 41
        | 44;
    showDach?: boolean;
    footer?: Array<string>;
    giftCard?: boolean;
    hideGiftcardEmail?: boolean;
    showStudentPromo?: boolean;
    studentOffer?: true;
    vatNumber: string;
    domain?: string;
    marketCode?: string;
    additionalCreditCards?: Array<string>;
    includeMagicLinkOnReturnUrls?: boolean;
    showPartners?: boolean;
};

type MarketConfigs = Record<Market, MarketConfig>;

export const marketConfigs: MarketConfigs = {
    at: {
        key: 'austria',
        name: 'Austria',
        code: 43,
        showDach: true,
        footer: ['impressum'],
        giftCard: true,
        showStudentPromo: true,
        studentOffer: true,
        vatNumber: 'DE325595875'
    },
    be: {
        key: 'belgium',
        name: 'Belgium',
        code: 32,
        showStudentPromo: true,
        studentOffer: true,
        giftCard: true,
        vatNumber: 'SE556560458301'
    },
    bg: {
        key: 'bulgaria',
        name: 'Bulgaria',
        code: 359,
        vatNumber: 'SE556560458301'
    },
    hr: {
        key: 'croatia',
        name: 'Croatia',
        code: 385,
        vatNumber: 'SE556560458301'
    },
    cy: {
        key: 'cyprus',
        name: 'Cyprus',
        code: 357,
        vatNumber: 'SE556560458301'
    },
    cz: {
        key: 'czechia',
        name: 'Czech Republic',
        code: 420,
        vatNumber: 'SE556560458301'
    },
    dk: {
        key: 'denmark',
        name: 'Denmark',
        code: 45,
        footer: ['press', 'work'],
        giftCard: true,
        showStudentPromo: true,
        studentOffer: true,
        vatNumber: 'SE556560458301',
        additionalCreditCards: ['dankort'],
        showPartners: true
    },
    ee: {
        key: 'estonia',
        name: 'Estonia',
        code: 372,
        vatNumber: 'SE556560458301'
    },
    fi: {
        key: 'finland',
        name: 'Finland',
        code: 358,
        footer: ['school'],
        giftCard: true,
        vatNumber: 'FI16552213',
        showStudentPromo: true,
        studentOffer: true
    },
    fr: {
        key: 'france',
        name: 'France',
        code: 33,
        giftCard: !delocalizationEnabled,
        hideGiftcardEmail: true,
        vatNumber: 'SE556560458301'
    },
    de: {
        key: 'germany',
        name: 'Germany',
        code: 49,
        showDach: true,
        footer: ['press', 'impressum', 'work'],
        giftCard: true,
        showStudentPromo: true,
        studentOffer: true,
        vatNumber: 'DE325595875'
    },
    gr: {
        key: 'greece',
        name: 'Greece',
        code: 30,
        vatNumber: 'SE556560458301'
    },
    hu: {
        key: 'hungary',
        name: 'Hungary',
        code: 36,
        vatNumber: 'SE556560458301'
    },
    ie: {
        key: 'ireland',
        name: 'Ireland',
        code: 353,
        vatNumber: 'SE556560458301'
    },
    it: {
        key: 'italy',
        name: 'Italy',
        code: 39,
        giftCard: !delocalizationEnabled,
        hideGiftcardEmail: true,
        vatNumber: 'SE556560458301'
    },
    lv: {
        key: 'latvia',
        name: 'Latvia',
        code: 371,
        vatNumber: 'SE556560458301'
    },
    lt: {
        key: 'lithuania',
        name: 'Lithuania',
        code: 370,
        vatNumber: 'SE556560458301'
    },
    lu: {
        key: 'luxembourg',
        name: 'Luxembourg',
        code: 352,
        vatNumber: 'SE556560458301'
    },
    mt: {
        key: 'malta',
        name: 'Malta',
        code: 356,
        vatNumber: 'SE556560458301'
    },
    nl: {
        key: 'netherlands',
        name: 'Netherlands',
        code: 31,
        footer: ['press', 'work'],
        showStudentPromo: true,
        studentOffer: true,
        giftCard: true,
        vatNumber: 'SE556560458301',
        includeMagicLinkOnReturnUrls: getEnvironment() !== 'local'
    },
    no: {
        key: 'norway',
        name: 'Norway',
        code: 47,
        footer: ['press', 'work'],
        showStudentPromo: true,
        studentOffer: true,
        giftCard: true,
        vatNumber: 'SE556560458301'
    },
    pl: {
        key: 'poland',
        name: 'Poland',
        code: 48,
        footer: ['press', 'work'],
        giftCard: true,
        showStudentPromo: true,
        studentOffer: true,
        vatNumber: 'SE556560458301'
    },
    pt: {
        key: 'portugal',
        name: 'Portugal',
        code: 351,
        vatNumber: 'SE556560458301'
    },
    ro: {
        key: 'romania',
        name: 'Romania',
        code: 40,
        vatNumber: 'SE556560458301'
    },
    sk: {
        key: 'slovakia',
        name: 'Slovakia',
        code: 421,
        vatNumber: 'SE556560458301'
    },
    si: {
        key: 'slovenia',
        name: 'Slovenia',
        code: 386,
        vatNumber: 'SE556560458301'
    },
    es: {
        key: 'spain',
        name: 'Spain',
        code: 34,
        giftCard: !delocalizationEnabled,
        hideGiftcardEmail: true,
        vatNumber: 'SE556560458301'
    },
    se: {
        key: 'sweden',
        name: 'Sweden',
        code: 46,
        footer: ['school'],
        giftCard: true,
        vatNumber: 'SE556560458301',
        showStudentPromo: true,
        studentOffer: true
    },
    ch: {
        key: 'switzerland',
        name: 'Switzerland',
        code: 41,
        showDach: true,
        footer: ['impressum'],
        giftCard: true,
        showStudentPromo: true,
        studentOffer: true,
        vatNumber: 'CHE-174.698.824'
    },
    uk: {
        key: 'united-kingdom',
        name: 'United Kingdom',
        code: 44,
        vatNumber: 'SE556560458301',
        giftCard: true,
        hideGiftcardEmail: true,
        domain: 'co.uk'
    }
};

export const isValidMarket = (market?: string): market is Market =>
    Boolean(marketConfigs[market as Market]);

export const getGbMarketAdjustment = (market: Market | string) => {
    if (isValidMarket(market)) {
        return market;
    }
    // Return 'uk' if market param is 'gb' - all other values should be caught in the if statement above.
    return 'uk';
};

export const getMarketConfig = (market: Market | string): MarketConfig => {
    const adjustedMarket = getGbMarketAdjustment(market);

    return {
        ...marketConfigs[adjustedMarket],
        marketCode: adjustedMarket
    };
};

export const getAllMarketConfigsAsArray = () =>
    Object.entries(marketConfigs).map(([key, value]) => ({
        marketCode: key,
        ...value
    }));
