import { type BBApiTypes } from 'bb/api/types';
import { type ImageData } from 'bb/common/images/types';
import { type Product } from 'bb/subscription/types';
import { type PageConfigType } from './config';

export type CampaignTerm = {
    id: string;
    checkboxText?: string;
    linkText?: string;
    code?: string;
    title?: string;
    document?: {
        fields: {
            file: {
                url: string;
            };
        };
    };
};

export type CampaignPage = {
    isCampaign: boolean;
    type: PageConfigType;
    name: string;
    path: string;
    image?: ImageData;
    imageUrl?: string;
    pageTitle: string;
    header: string;
    logo?: ImageData;
    code?: string;
    usps?: string;
    affiliate?: string;
    footer?: string;
    showHowItWorksSection?: boolean;
    showCode?: boolean;
    hideSubheader?: boolean;
    buttonTextOverride?: string;
    bookListId?: number;
    countdownToDate?: string;
};

export enum PriceRuleTypes {
    percent = 'percentagediscount',
    relative = 'relativediscount',
    fixed = 'fixedprice',
    ongoing = 'ongoingcampaign'
}

export type PriceRule = {
    lifeTimeInDays: number;
    lifeTimeInPeriods: number;
    productDiscount: number | null;
    profileDiscount: number | null;
    type: PriceRuleTypes;
    limitedForProducts: string[];
};

export enum EligibilityRule {
    personalIdentificationNumber = 'personalidentificationnumber',
    excludeExisting = 'excludeexistingcustomer',
    excludeNew = 'existingcustomeronly',
    uniqueCodeOnce = 'uniquecodeonce',
    sheerIdVerification = 'sheeridverification',
    nordicchoice = 'nordicchoice',
    nordicchoiceMemberId = 'nordicchoicememberid',
    nordicchoiceEmail = 'nordicchoiceemail'
}

export type EligibilityRuleValue =
    | 'personalidentificationnumber'
    | 'excludeexistingcustomer'
    | 'existingcustomeronly'
    | 'uniquecodeonce'
    | 'sheeridverification'
    | 'nordicchoice'
    | 'nordicchoicememberid'
    | 'nordicchoiceemail';

export enum VerificationError {
    sheerIdAlreadyVerifiedAndActive = 'sheeridalreadyverifiedandactive',
    sheerIdReachedVerificationLimit = 'sheeridreachedverificationlimit',
    sheerIdVerificationIdUsedOnOtherAccount = 'sheeridverificationidusedonotheraccount',
    sheerIdVerificationIdMissing = 'sheeridverificationidmissing',
    alreadyUsedCodeForSameCampaign = 'already_used_code_for_same_campaign',
    errorExcludeActiveSubscribers = 'new_and_in_active_customer_only'
}

export type CampaignConditionalOffer = BBApiTypes['ApiConditionalOfferDto'];

export type Campaign = {
    code: string;
    hasExpired: boolean;
    ignoreActivation: boolean;
    trialDays: number;
    hourLimit?: number;
    eligibilityRules: EligibilityRule[];
    terms?: CampaignTerm[];
    priceRule?: PriceRule;
    priceRules?: PriceRule[];
    waitForValidation?: boolean;
    hasExternalConsents?: boolean;
    isUcCampaign?: boolean;
    products?: Product[];
    willActivateSubscriptionOnAdd?: boolean;
    activationStartDate?: string | null;
    activationEndDate?: string | null;
    hasPrepaidDays?: boolean;
    conditionalOffer?: CampaignConditionalOffer | null;
};

export type DefaultCampaignResponse = BBApiTypes['ApiDefaultCampaignDto'];

export type CampaignResponse = BBApiTypes['ApiValidateCampaignDto'];

export type CampaignProductResponse = NonNullable<
    CampaignResponse['products']
>[number];

export type PendingCampaignResponse =
    BBApiTypes['ApiUpsertPendingCampaignRequest'];

export type OngoingCampaignResponse = {
    campaignName: string;
    validTo: string;
    isLifeTimeOffer: boolean;
    campaignCode: string;
    hasExternalConsents: boolean;
};

export type ProfileCampaignOfferResponse = {
    offer: {
        validTo: string;
        productPriceRules: PriceRule[];
        numberOfProfiles: number;
    };
};

export type PersonalReactivationOfferResponse =
    BBApiTypes['ApiActivePersonalOffer'];

export type CampaignFormValues = {
    campaignCode: string;
    sheeridverification?: string;
    personalidentificationnumber?: string;
    nordicchoicememberid?: string;
    nordicchoiceemail?: string;
    isEligibleForConditionalOffer?: string;
};

export type CampaignPayload = {
    campaignCode: string;
    additionalData?: Record<string, string>;
};

export type ApplyCampaignResponse = {
    Message: string;
};

export type CampaignStore = {
    campaign?: Campaign;
    campaignCode?: string;
    campaignFormValues?: CampaignFormValues;
    isWaitingForAdditionalData?: boolean;
    setCampaign(campaign: Campaign): void;
    setCampaignCode(code: string, data: CampaignFormValues): void;
    clearCampaign(): void;
    setIsWaitingForAdditionalData(isWaitingForAdditionalData: boolean): void;
};

export type ReactivationOffer = {
    offer: {
        ProductPriceRules: Array<PriceRule>;
        enddate: string;
        isValid: boolean;
        nrofmonths: number;
        price: number;
        originalprice: number;
        subscriptionproducttype: string;
        subscriptionproductname: string;
        nextsubscriptionproductname: string;
        nextsubscriptionproductoriginalprice: number;
        hourLimit: number;
        numberOfPeriodsGivenWhenApplied: BBApiTypes['ApiReactivationOffer']['numberOfPeriodsGivenWhenApplied'];
    };
};

export type TopUpProductsResponse = {
    topUpProducts: Array<TopUpProduct>;
    currentSubscriptionPeriodTo: Date;
};

export type TopUpProduct = {
    id: string;
    displayName: string;
    hours: number;
    price: number;
    discountPercentage?: number;
    discountPrice?: number;
    hasDiscount?: boolean;
};
