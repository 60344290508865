import React from 'react';
import classNames from 'classnames';
import { Box, type BoxProps } from '../Box';
import { Gap } from '../Gap';
import { ImageIcon, type SvgName } from '../Icons';
import css from './badge.module.scss';

export type BadgeBaseProps = {
    icon: SvgName;
};

export type BadgeProps = BadgeBaseProps & Omit<BoxProps, 'padding'>;

export const MonochromeBadge = (props: BadgeProps) => {
    const {
        icon,
        className,
        bgColor = 'tertiary-purple',
        children,
        ...rest
    } = props;

    return (
        <Box
            bgColor={bgColor}
            padding={[1, 2, 1, 1]}
            className={classNames(css.base, className)}
            {...rest}
        >
            <Gap spacing={2} alignItems="center" direction="row">
                {icon && <ImageIcon svg={icon} size="small" />}
                {children}
            </Gap>
        </Box>
    );
};
