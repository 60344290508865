import React, { type CSSProperties } from 'react';
import classnames from 'classnames';
import css from './col.module.scss';

export type ColProps = {
    className?: string;
    style?: CSSProperties;
    span?: 12 | 6 | 4 | 3 | 2 | 1;
    children?: React.ReactNode;
} & React.ComponentPropsWithRef<'div'>;

export const Col = ({
    ref,
    className,
    style,
    span,
    children,
    ...restProps
}: ColProps) => (
    <div
        ref={ref}
        className={classnames(css.base, css[`span-${span}`], className)}
        style={style}
        {...restProps}
    >
        {children}
    </div>
);
