import React from 'react';
import { useTranslation } from 'bb/i18n';
import { AtIcon } from 'bb/ui/Icons';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type EmailInputProps = Omit<InputProps, 'startAdornment' | 'type'>;

export const EmailInput = ({ ref, ...props }: EmailInputProps) => {
    const { t } = useTranslation(['inputFields']);
    const {
        label = t('inputFields:labelEmail'),
        placeholder = t('inputFields:placeholderEmail'),
        ...restProps
    } = props;

    return (
        <Input
            type="email"
            startAdornment={<AtIcon size="small" />}
            ref={ref}
            label={label}
            placeholder={placeholder}
            {...restProps}
        />
    );
};
