import React, { useEffect, useRef } from 'react';

export type UniqueScriptInjectorProps = {
    id: NonNullable<React.ComponentProps<'script'>['id']>;
} & Omit<React.ComponentProps<'script'>, 'id'>;

export const UniqueScriptInjector = (props: UniqueScriptInjectorProps) => {
    const { id, ...restProps } = props;
    const isLoadedRef = useRef(false);
    useEffect(() => {
        const scriptElement = document.getElementById(id);
        if (scriptElement) {
            isLoadedRef.current = true;
        }
    }, [id]);
    if (isLoadedRef.current) return null;
    return <script id={id} {...restProps} />;
};
