/* eslint-disable react/jsx-props-no-spreading */
import React, { useId } from 'react';
import classNames from 'classnames';
import { DummyInput, type DummyInputProps } from '../../DummyInput';
import { Stack } from '../../Flex';
import { Gap } from '../../Gap';
import { Typography } from '../../Typography';
import { HelperText } from '../HelperText';
import { type LabelProps } from '../Label';
import { type InputCommonProps } from '../types';
import css from './checkbox.module.scss';

export type CheckboxProps = {
    label?: LabelProps['children'];
    className?: string;
} & Omit<DummyInputProps, 'render'> &
    Pick<InputCommonProps, 'error' | 'helperText'>;

/**
 * This component is built on the DummyInput component since we
 * are in need of checkbox button behaviour, but with custom styling.
 */
export const Checkbox = ({ ref, ...props }: CheckboxProps) => {
    const {
        label,
        className,
        error,
        labelProps: {
            'aria-label': passedAriaLabel,
            htmlFor,
            ...labelProps
        } = {},
        helperText,
        required = false,
        ...restProps
    } = props;

    const id = useId();
    /**
     * Default aria-label to the value of the label if it's a string.
     */
    const ariaLabel = typeof label === 'string' ? label : passedAriaLabel;

    return (
        <DummyInput
            ref={ref}
            {...restProps}
            labelProps={{
                ...labelProps,
                'aria-label': ariaLabel,
                htmlFor: htmlFor ?? id
            }}
            type="checkbox"
            aria-required={required}
            aria-invalid={Boolean(error)}
            id={id}
            render={() => (
                <Stack
                    className={classNames(css.container, className)}
                    alignItems="flexStart"
                >
                    <Gap spacing={4} direction="row">
                        <span className={css.input} />
                        <div>
                            <Typography block variant="body3">
                                {label}
                            </Typography>
                            <HelperText error={error}>
                                {error || helperText}
                            </HelperText>
                        </div>
                    </Gap>
                </Stack>
            )}
        />
    );
};
