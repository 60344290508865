import { MobileAgents } from '../utils/assert';

export const IOS_AGENTS = [
    MobileAgents.iPad,
    MobileAgents.iPhone,
    MobileAgents.iPod
];

export const ANDROID_AGENTS = [MobileAgents.Android];

export const CRAWLER_AGENTS = [
    'Googlebot',
    'Bingbot',
    'Slurp',
    'DuckDuckBot',
    'Baiduspider',
    'YandexBot',
    'Sogou',
    'Exabot',
    'facebot',
    'ia_archiver',
    'MJ12bot',
    'AhrefsBot',
    'SemrushBot',
    'DotBot',
    'SeznamBot',
    'rogerbot',
    'Mediapartners-Google',
    'AdsBot-Google',
    'Googlebot-Image',
    'Googlebot-Video',
    'Googlebot-News',
    'Googlebot-Mobile',
    'Googlebot-Desktop'
];

export const E2E_USER_AGENTS = [
    /**
     * This user agent is a custom header set by our own cypress tests (QA team).
     */
    'cypress',
    /**
     * This user agent is a custom header set by our own playwright tests.
     */
    'playwright'
];
